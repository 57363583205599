import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Form from '../../components/Form'
import './index.css'
import { Link } from 'gatsby'

import arrow from '../../images/arrow.svg'
import service1 from '../../images/services-image1.jpg'
import service2 from '../../images/services-image2.jpg'
import service3 from '../../images/services-image3.jpg'


const ServicesPage = () => (
    <Layout>
        <SEO title='CPA in Pasadena Maryland'/>
        <section>
            <div className='services-header'>
                <h1 style={{color: `#B62020`}}>SERVICES</h1>
                <p>
                The number one reason small businesses hire accountants or outsource their bookkeeping for tax season is 
                because accountants can save you money. The tax system is complex, and it takes time and experience for 
                someone to learn how it works. When you hire a tax professional, you’re getting someone who has helped 
                out plenty of businesses like yours with their taxes. They know how it’s done and they know how to minimize 
                your liability and maximize your possible refund. 
                <br/>
                <br/>
                According to a survey of roughly 400 small businesses in the United States, 52% believed that they overpay 
                in taxes each year. But according to that same survey, accountants are the most important professionals 
                for small businesses – ahead of attorneys – and an average 67% of small businesses are satisfied or very 
                satisfied with their accountants. While you might be able to get your taxes done on your own without any help, 
                you might never know how much money you’re leaving on the table. An accountant will have the knowledge and 
                expertise to ensure you’re not overpaying, and that you’re claiming all the deductions you deserve.
                </p>
                <Link id='services-appointment-link' to='/md-cpa/'>schedule an appointment</Link>
             
        
            </div>
        </section>

        <section>
          <div className='services-section'>
            <div className='services-section-bottom'>
              <div className='individual-service'>
                <div className='individual-service-top'>
                  <div className='individual-service-photo-container'>
                    <img src={service1} alt='fix this alt'/>
                  </div>
                  <p style={{margin: `0`}}>Accounting</p>
                </div>
                <div className='individual-service-center'>
                <p>
                Accounting is the process of recording financial transactions pertaining 
                to a business. The accounting process includes summarizing, analyzing 
                and reporting these transactions to oversight agencies, regulators and 
                tax collection entities.
                <br/>
                <br/>
                I can replace your back office with accounting, payroll, and bookkeeping 
                support. When it comes to complex issues, I am a trusted partner.
                </p>
                </div>
                <div className='individual-service-bottom'>
                  <Link id='circular-link' to='/services/cpa-columbia-md'>
                    <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
                  </Link>
                  <p>DETAILS</p>
                </div>
              </div>
              <div className='individual-service'>
                <div className='individual-service-top'>
                  <div className='individual-service-photo-container'>
                    <img src={service2} alt='fix this alt'/>
                  </div>
                  <p style={{margin: `0`}}>Bookkeeping</p>
                </div>
                <div className='individual-service-center'>
                  <p>
                    Bookkeeping is the process of keeping track of every financial 
                    transaction made by a business firm from the opening of the firm 
                    to the closing of the firm. Depending on the type of accounting 
                    system used by the business, each financial transaction is recorded 
                    based on supporting documentation. 
                    <br/>
                    <br/>
                    I can help with all of your business bookkeeping needs.
                  </p>
                </div>
                <div className='individual-service-bottom'>
                  <Link id='circular-link' to='/services/accurate-accounting-glen-burnie'>
                    <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
                  </Link>
                  <p>DETAILS</p>
                </div>
              </div>
              <div className='individual-service'>
                <div className='individual-service-top'>
                  <div className='individual-service-photo-container'>
                    <img src={service3} alt='fix this alt'/>
                  </div>
                  <p style={{margin: `0`}}>Professional Courtesy</p>
                </div>
                <div className='individual-service-center'>
                  <p>Free tax return check-up - I will review your prior tax returns and advise you of any errors</p>
                  <p>Discount on first-year tax preparation fees.</p>
                </div>
                <div className='individual-service-bottom'>
                  <Link id='circular-link' to='/services/cpa-annapolis-md'>
                    <img style={{height: `30px`, width: `30px`}} src={arrow} alt='arrow icon'/>
                  </Link>
                  <p>DETAILS</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className='contact-section'>
            <div className='contact-section-top'>
              <p style={{margin: `0`}}>CONTACT</p>
            </div>
            <div className='contact-section-bottom'>
              <div className='contact-section-bottom__left'>
                <p>
                Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
                <br/>
                <br/>
                or
                <br/>
                <br/>
                Fill out the contact form and I'll reach out to you as soon as possible.
                </p>
              </div>
              <div className='contact-section-bottom__right'>
                <Form />
              </div>
            </div>
          </div>
        </section>
    </Layout>
)

export default ServicesPage